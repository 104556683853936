import React,{useState} from 'react';
import './Seo.css';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Star from '../Components/Star';
import ServAnimate from '../Components/ServAnimate';
import logo86 from "../images/se.png";




function Seo() {
  const [animate, setAnimate] = useState(false);

  const startAnimation = () => {
    setAnimate(true);
  };
  return (
    <div className="App">
     <Header/>
     
      <ServAnimate></ServAnimate>
    

      
      
      <div className="p-5 my-5 section2 text-white" style={{backgroundColor:"#EF259B",justifyContent:"left"}}>

                    <h1>What is SEO?</h1>
                    <p style={{fontSize:'25px',fontFamily:'inter'}}>You can attract all the visitors in the world, but if they're coming to your 
site because Google tells them you're a resource for Apple computers 
when really you're a farmer selling apples, that is not quality traffic.
Instead you want to attract visitors who are genuinely interested in
products that you offer.</p>
                         <h3 style={{color:'#4F21A7'}}>What do we serve?</h3>
                         <p style={{fontWeight: '700', fontSize: '33px'}}>A strategy and a broad spectrum of creative brand marketing services in the digital world.</p>
                         <div className="section3 row">
                         <div className="col-2">SEO</div>
                         <div className="col-2">SMM</div>
                         <div className="col-3">Content Marketing</div>
                         <div className="col-3">Graphics Design</div>
                         <div className="col-3">AI Marketing</div>
                         <div className="col-3">AI Marketing</div>
                         <div className="col-3">Google Ad's</div>
                         <div className="col-4">Website Developement</div>
                         </div>
                </div>
      
      <FAQ />
      
      
    <Footer/>
    </div>
  );
}

export default Seo;