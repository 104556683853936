import React,{useState} from 'react';
import './Seo.css';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Star from '../Components/Star';
import ServAnimate from '../Components/ServAnimate';
import logo86 from "../images/se.png";
import ellipse from '../images/Ellipse 257.png';
import m16 from "../images/dgreen.png";
import m17 from "../images/pink.png";




function Graphic() {
  const [animate, setAnimate] = useState(false);

  const startAnimation = () => {
    setAnimate(true);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="App">
     <Header/>
     
                <section className="intro-section">
                <h1 className='text-start' style={{color:'blueviolet',textAlign:'left',marginLeft:'-970px',marginTop:'-81px',fontWeight:"bold"}}>Graphics Design</h1>
       <div className='row'>
        <div className='col-md-6 col-sm-12 col-lg-6'>

        <img style={{marginTop:'-80px',marginLeft:'700px' , width:'50px',height:'50px'}} src={m16}></img>

        <img style={{marginTop:'-227px',marginLeft:'1038px' , width:'28px',height:'28px'}} src={m17}></img>



          <p> Tell your brand story
with our high-quality
graphics that grab the 
world's attention. We 
design flyers, brochures, 
catchy posts, logos, and
captivating presentations
that create your brand's 
impact on the audience. </p>
        </div>
       <div className="col-sm-12 col-md-6 col-lg-6 shape-container mt-5">
           
            <div className='cir1'></div>
            <div className='filled'>
              <div className='unfilled'></div>
            </div>
            <Star></Star>
            <div className='diagonal'></div>
            <div className='polygon'></div>
            <div className='line'></div>
            <div className='square1'></div>
            <div className='triangle'></div>
            <div className='half-circle'></div>
            <div className='bigcir'> <img src={ellipse} alt="Description 2" style={{animation:'rotate 5s linear infinite',width:'100px',height:'100px'}} /></div>
            <div className='smallcir'></div>
    </div> 
       </div>
          
      </section>
    
    

      
    

      
     

      <div className="p-5 my-5 section2 text-white" style={{backgroundColor:"#EF259B",justifyContent:"left"}}>

                    <h1>What is Graphic Design?</h1>
                    <p style={{fontSize:'25px',fontFamily:'inter'}}> Tell your brand story with our high-quality graphics that grab the world's 
attention. We design flyers, brochures, catchy posts, logos, and captivating
presentations that create your brand's impact on the audience. Get yourself
a designer for your imagination.
</p>
                         <h3 style={{color:'#4F21A7'}}>what do we serve?</h3>
                         <p style={{fontWeight: '700', fontSize: '33px'}}>We can create all kinds of imagery and 
graphical elements at the client’s request.
With proper visuals and page layout, we tell
the brand story in a way that grabs the 
audience's attention.</p>
                         <div className="section3 row">
                         <div className="col-2">SEO</div>
                         <div className="col-2">SMM</div>
                         <div className="col-3">Content Marketing</div>
                         <div className="col-3">Graphics Design</div>
                         <div className="col-3">AI Marketing</div>
                         <div className="col-3">AI Marketing</div>
                         <div className="col-3">Google Ad's</div>
                         <div className="col-4">Website Developement</div>
                         </div>
                </div>
      
                <section className="faq-section">
      <h2>Frequently Asked Questions (FAQ)</h2>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(0)}>
        Do I need a graphic designer for my small business?

        </button>
        <div className={`faq-answer ${activeIndex === 0 ? 'show' : ''}`}>
          <p>Even small businesses can benefit from professional graphic design.  A well-designed logo, website, or marketing materials can make a big difference in your brand's image and customer perception.
          </p>
          
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(1)}>
        What is the typical design process?


        </button>
        <div className={`faq-answer ${activeIndex === 1 ? 'show' : ''}`}>
          <p>The graphic design process typically involves four steps: understanding the client's goals (briefing), brainstorming and creating ideas (concept development), refining the design based on feedback, and finalizing and delivering the files.
          </p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(2)}>
        What should I look for when hiring a graphic designer?

        </button>
        <div className={`faq-answer ${activeIndex === 2 ? 'show' : ''}`}>
          <p>Choosing a graphic designer? Focus on three things: a portfolio matching your brand style and project needs, experience in your industry for best results, and clear communication to ensure they understand your vision. This will help you find the perfect designer to bring your project to life.
          </p>
        </div>
      </div>
    </section>
    <Footer/>
    </div>
  );
}

export default Graphic;