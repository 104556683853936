import React, { useEffect, useState } from "react";
import './Section1.css';
import logo from '../images/RuvAa 1.png';
import news from "../images/News.png";
import c1 from "../images/c1.png";
import c2 from "../images/c2 (1).png";
import c3 from "../images/c2 (2).png";
import c4 from "../images/c2 (3).png";
import c5 from "../images/c2 (4).png";
import logo86 from "../images/circle.png";
import logo87 from "../images/circle4.png";

import Lottie from 'react-lottie';

import face from "../images/Facebook.png";
import insta from "../images/Instagram.png";
import linkedin from "../images/Linkedin.png";
import twitter from "../images/twiter.png";
import you from "../images/Youtube.png";








import * as arrowAnimation from '../images/Animation - 1719984554482.json';

function Section1() {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: arrowAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="container-fluid slogan" style={{ marginTop: '0px' }}>
            <div className="row">
                <div className="col p-5 box ">
                    <img src={logo} alt="img1" width={200} className="mb-3"></img>
                    <img style={{ marginLeft: 50, width: 34, height: 34 }} src={logo87}></img>
                    <h1 style={{ fontWeight: 'bolder', fontSize: 70 }}>Pune's Brightest<br /> digital agency</h1>
                    <div className="img8">
                        <img style={{ marginLeft: '198px', marginTop: '-42px', width: 45, height: 45 }} src={logo86}></img>
                    </div>
                    <p style={{ marginBottom: '-22px' }}>
                        {isSmallScreen ? (
                            <>
                                Get your brand recognized by people with our<br />
                                marketing channel. Branding, Marketing, SEO,<br />
                                SMM & SEM all at one place.
                            </>
                        ) : (
                            "Get your brand recognized by people with our marketing channel. Branding, Marketing, SEO, SMM & SEM all at one place."
                        )}
                    </p>


                    <div style={{ marginTop: '34px' }}>
    <a href="https://www.instagram.com/ruva_digital_media/profilecard/?igsh=MXhhbWJwbnl0dG0yeQ==" target="_blank" rel="noopener noreferrer">
        <img src={insta} style={{ height: '36px', margin: '4px' }} alt="Instagram" />
    </a>
    <a href="https://www.facebook.com/profile.php?id=61560580079638&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={face} style={{ height: '36px', margin: '4px' }} alt="Facebook" />
    </a>
    <a href="https://youtube.com/@ruvadigitalmedia?si=aMGTa7oFepfr5B5U
" target="_blank" rel="noopener noreferrer">
        <img src={you} style={{ height: '36px', margin: '4px' }} alt="YouTube" />
    </a>
    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
        <img src={twitter} style={{ height: '36px', margin: '4px' }} alt="Twitter" />
    </a>
    <a href="https://www.linkedin.com/company/ruva-digital-media/?viewAsMember=tru" target="_blank" rel="noopener noreferrer">
        <img src={linkedin} style={{ height: '36px', margin: '4px' }} alt="LinkedIn" />
    </a>
</div>



                    <div className="button-container">
                        <button type="button" className="btn btn-lg btn-outline-dark btn2"><b>WATCH VIDEO</b></button><br></br>
                    </div>
                </div>
                <div className="col">
    <div className="cylinder">
        <div className="poly">
            <img src={news} className="img1" alt="image" width={124} />
        </div>
        <div className="poly poly1">
            <img src="" alt="" className="img2 rounded-circle" width={130} />
        </div>
        <img src={c2} className="img3" alt="image" width={150} />
    </div>
    <div className="cylinder1">
        <img src={c3} alt="image" className="img4" width={124} />
        <div className="poly poly2"></div>
        <div className="poly poly3">
            <img src={c4} alt="image" className="img5" width={124} />
        </div>
        <div className="poly poly4">
            <img src={c5} alt="image" className="img7" width={124} />
        </div>
        <img src={c1} alt="image" className="img6" width={150} />
    </div>
</div>

            </div>
            {/* <div className="arrow-animation">
                <Lottie options={defaultOptions} height={300} width={300} />
            </div> */}
        </div>
    );
}

export default Section1;
