import React, { useState } from 'react';
import './Seo.css';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Star from '../Components/Star';
import ServAnimate from '../Components/ServAnimate';
import logo86 from "../images/se.png";
import ellipse from '../images/Ellipse 257.png';
import m16 from "../images/dgreen.png";
import m17 from "../images/pink.png";

function AppDevelopment() {
  const [animate, setAnimate] = useState(false);

  const startAnimation = () => {
    setAnimate(true);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="App">
      <Header />

      <section className="intro-section">
        <h1 className='text-start' style={{ color: 'blueviolet', textAlign: 'left', marginLeft: '-970px', marginTop: '-81px', fontWeight: "bold" }}>App Development</h1>
        <div className='row'>
          <div className='col-md-6 col-sm-12 col-lg-6'>
            <img style={{ marginTop: '-80px', marginLeft: '618px', width: '50px', height: '50px' }} src={m16} alt="m16" />
            <img style={{ marginTop: '-163px', marginLeft: '1038px', width: '28px', height: '28px' }} src={m17} alt="m17" />
            <p>In today's mobile-first world, a captivating app can be your game-changer. It puts your brand directly in users' pockets, offering instant engagement and personalized experiences. Whether you need a sleek consumer app, a powerful business tool, or something unique, we can turn your vision into reality.</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 shape-container">
            <div className='cir1'></div>
            <div className='filled'>
              <div className='unfilled'></div>
            </div>
            <Star />
            <div className='diagonal'></div>
            <div className='polygon'></div>
            <div className='line'></div>
            <div className='square1'></div>
            <div className='triangle'></div>
            <div className='half-circle'></div>
            <div className='bigcir'>
              <img src={ellipse} alt="Description 2" style={{ animation: 'rotate 5s linear infinite', width: '100px', height: '100px' }} />
            </div>
            <div className='smallcir'></div>
          </div>
        </div>
      </section>

      <div className="p-5 my-5 section2 text-white" style={{ backgroundColor: "#EF259B", justifyContent: "left" }}>
        <h1>What is App Development?</h1>
        <p>App development is the process of creating software applications specifically designed for mobile devices, tablets, or even wearables. It involves planning, designing, coding, testing, and deploying the app on various app stores.</p>
        <h3 style={{ color: 'purple' }}>What do we serve?</h3>
        <p style={{ fontWeight: '700', fontSize: '33px' }}>Our expert app developers can craft a custom solution tailored to your specific needs. We'll guide you through the entire process, from brainstorming ideas to building a user-friendly interface, robust functionalities, and seamless integration with other platforms. We'll also ensure your app adheres to app store guidelines and optimize it for discoverability.</p>
        <div className="section3 row">
          <div className="col-2">SEO</div>
          <div className="col-2">SMM</div>
          <div className="col-3">Content Marketing</div>
          <div className="col-3">Graphics Design</div>
          <div className="col-3">AI Marketing</div>
          <div className="col-3">AI Marketing</div>
          <div className="col-3">Google Ad's</div>
          <div className="col-4">Website Development</div>
        </div>
      </div>

      <section className="faq-section">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(0)}>
            How much does a company charge to build an app?
          </button>
          <div className={`faq-answer ${activeIndex === 0 ? 'show' : ''}`}>
            <p>The cost of building an app can vary significantly depending on several factors, making it difficult to give a one-size-fits-all answer. Depending upon the app's complexity, platform, tech used, design, and features cost can vary. Some company charges on an hourly basis. The average costs could range between 3L-5L.</p>
          </div>
        </div>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(1)}>
            Do mobile app developers in Pune provide a payment gateway facility for eCommerce apps?
          </button>
          <div className={`faq-answer ${activeIndex === 1 ? 'show' : ''}`}>
            <p>Yes, there are many companies that provide payment gateway connectivity. Ruva Digital Media has tie-ups with payment companies. Plus, we ensure complete transaction security and multilayer protection from hackers.</p>
          </div>
        </div>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(2)}>
            What are the advantages of hiring a mobile app development company?
          </button>
          <div className={`faq-answer ${activeIndex === 2 ? 'show' : ''}`}>
            <p>Hiring a mobile app development company offers access to expert developers, streamlines the process, and can be more cost-effective, resulting in a high-quality app delivered faster.</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default AppDevelopment;
