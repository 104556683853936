import React from "react";
import './Footer.css';
import Logo from '../images/Ruva logo white ps 1.png';


import face from "../images/Facebook.png";
import insta from "../images/Instagram.png";
import linkedin from "../images/Linkedin.png";
import twitter from "../images/twiter.png";
import you from "../images/Youtube.png";

function Footer() {
    return ( 
        <React.Fragment>
            <div className="color-bg">
                <div className="row text-white mx-auto justify-content-center">
                    <div className="col-sm-12 col-lg-3 mb-3 text-center">
                        <img src={Logo} alt="logo" className="mx-auto d-block img-fluid" width={150}/>
                        <div style={{ marginTop: '34px' }}>
    <a href="https://www.instagram.com/ruva_digital_media/profilecard/?igsh=MXhhbWJwbnl0dG0yeQ==" target="_blank" rel="noopener noreferrer">
        <img src={insta} style={{ height: '36px', margin: '4px' }} alt="Instagram" />
    </a>
    <a href="https://www.facebook.com/profile.php?id=61560580079638&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={face} style={{ height: '36px', margin: '4px' }} alt="Facebook" />
    </a>
    <a href="https://youtube.com/@ruvadigitalmedia?si=aMGTa7oFepfr5B5U
" target="_blank" rel="noopener noreferrer">
        <img src={you} style={{ height: '36px', margin: '4px' }} alt="YouTube" />
    </a>
    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
        <img src={twitter} style={{ height: '36px', margin: '4px' }} alt="Twitter" />
    </a>
    <a href="https://www.linkedin.com/company/ruva-digital-media/?viewAsMember=tru" target="_blank" rel="noopener noreferrer">
        <img src={linkedin} style={{ height: '36px', margin: '4px' }} alt="LinkedIn" />
    </a>
</div>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-2 mb-3 text-center">
                        <h5 className="pb-2"><b>Services</b></h5>
                        <a href="#" className="text-white d-block my-3">Design</a>
                        <a href="#" className="text-white d-block my-3">Technology</a>
                        <a href="#" className="text-white d-block my-3">NeuroMarketing</a>
                        <a href="#" className="text-white d-block my-3">Digital Marketing</a>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-2 mb-3 text-center">
                        <h5 className="pb-2"><b>About</b></h5>
                        <a href="#" className="text-white d-block my-3">About Us</a>
                        <a href="#" className="text-white d-block my-3">Team</a>
                        <a href="#" className="text-white d-block my-3">Career</a>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-2 mb-3 text-center">
                        <h5 className="pb-2"><b>For Our Client</b></h5>
                        <a href="#" className="text-white d-block my-3">Work</a>
                        <a href="#" className="text-white d-block my-3">Client</a>
                        <a href="#" className="text-white d-block my-3">Blog</a>
                        <a href="#" className="text-white d-block my-3">Reach Us Marketing</a>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-2 text-center">
                        <h5 className="pb-2"><b>Quick Links</b></h5>
                        <a href="#" className="text-white d-block my-3">Privacy Policy</a>
                        <a href="#" className="text-white d-block my-3">Terms of use</a>
                        <a href="#" className="text-white d-block my-3">Site Map</a>
                        <a href="#" className="text-white d-block my-3">Refund Policy</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
     );
}

export default Footer;
