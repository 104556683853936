import React, { useState } from 'react';
import './Seo.css';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Star from '../Components/Star';
import ServAnimate from '../Components/ServAnimate';
import logo86 from "../images/se.png";
import ellipse from '../images/Ellipse 257.png';
import m16 from "../images/dgreen.png";
import m17 from "../images/pink.png";

function Webdevl() {
  const [animate, setAnimate] = useState(false);

  const startAnimation = () => {
    setAnimate(true);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="App">
      <Header />

      <section className="intro-section">
        <h1 className='text-start' style={{ color: 'blueviolet', textAlign: 'left', marginLeft: '-890px', marginTop: '-81px', fontWeight: "bold" }}>Website Development</h1>
        <div className='row'>
          <div className='col-md-6 col-sm-12 col-lg-6'>
            <img style={{ marginTop: '-80px', marginLeft: '618px', width: '50px', height: '50px' }} src={m16} alt="m16" />
            <img style={{ marginTop: '-163px', marginLeft: '1038px', width: '28px', height: '28px' }} src={m17} alt="m17" />
            <p>In today's digital age, having a proper website isn't a luxury - it's a necessity. It's your storefront, brochure, and most crucial lead-generation tool, all rolled into one. Let us help you design a responsive and dynamic website.</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 shape-container">
            <div className='cir1'></div>
            <div className='filled'>
              <div className='unfilled'></div>
            </div>
            <Star />
            <div className='diagonal'></div>
            <div className='polygon'></div>
            <div className='line'></div>
            <div className='square1'></div>
            <div className='triangle'></div>
            <div className='half-circle'></div>
            <div className='bigcir'>
              <img src={ellipse} alt="Description 2" style={{ animation: 'rotate 5s linear infinite', width: '100px', height: '100px' }} />
            </div>
            <div className='smallcir'></div>
          </div>
        </div>
      </section>

      <div className="p-5 my-5 section2 text-white" style={{ backgroundColor: "#EF259B", justifyContent: "left" }}>
        <h1>What is web development?</h1>
        <p>Web development is the process of creating websites and web applications. It involves everything from planning and designing the layout to writing the code that makes the website function.</p>
        <h3 style={{ color: 'purple' }}>What do we serve?</h3>
        <p style={{ fontWeight: '700', fontSize: '33px' }}>To maximise your online presence, we can craft a comprehensive web solution. This includes designing dedicated landing pages tailored for specific conversions, building a fully responsive website that seamlessly adapts to any device, and optimizing your site for search engines to ensure top visibility when potential customers search online</p>
        <div className="section3 row">
          <div className="col-2">SEO</div>
          <div className="col-2">SMM</div>
          <div className="col-3">Content Marketing</div>
          <div className="col-3">Graphics Design</div>
          <div className="col-3">AI Marketing</div>
          <div className="col-3">AI Marketing</div>
          <div className="col-3">Google Ad's</div>
          <div className="col-4">Website Development</div>
        </div>
      </div>

      <section className="faq-section">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(0)}>
            What does a web developer do?
          </button>
          <div className={`faq-answer ${activeIndex === 0 ? 'show' : ''}`}>
            <p>Web developers are the tech wizards behind the websites and web applications you use every day. They take a website from concept to creation, ensuring it functions flawlessly and looks great on any device.</p>
          </div>
        </div>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(1)}>
            Which language is mostly used in web development?
          </button>
          <div className={`faq-answer ${activeIndex === 1 ? 'show' : ''}`}>
            <p>There isn't one single most used language in web development, but a combination of languages depending on the specific function. However, JavaScript stands out as the most common and essential language for web development.</p>
          </div>
        </div>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(2)}>
            Do I need a web designer and a web developer?
          </button>
          <div className={`faq-answer ${activeIndex === 2 ? 'show' : ''}`}>
            <p>Web design focuses on the visual elements and user experience of a website, while web development deals with the technical aspects. Sometimes these roles can overlap, but for complex projects, you might need both a designer and a developer.</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Webdevl;
