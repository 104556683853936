import React, { useState, useEffect, useRef } from 'react';
import './Slogan.css';
import Sloganimg from "../images/sloganimg.png";

const Slogan = () => {
  const [count, setCount] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const ball1Ref = useRef(null);
  const ball2Ref = useRef(null);
  const ball3Ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust as needed to determine visibility
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible && count < 240) {
      interval = setInterval(() => {
        setCount(prevCount => {
          if (prevCount >= 240) {
            clearInterval(interval);
            return prevCount;
          }
          return prevCount + 1;
        });
      }, 1); // Adjust the speed of the counter as needed
    }

    return () => clearInterval(interval);
  }, [isVisible, count]);

  useEffect(() => {
    if (isVisible) {
      startAnimationSequence();
    }
  }, [isVisible]);

  const startAnimationSequence = () => {
    const ball1 = ball1Ref.current;
    const ball2 = ball2Ref.current;
    const ball3 = ball3Ref.current;

    const resetAnimations = () => {
      ball1.classList.remove('animate');
      ball2.classList.remove('animate');
      ball3.classList.remove('animate');
    };

    const startBall1 = () => {
      resetAnimations();
      ball1.classList.add('animate');
      setTimeout(startBall2, 2000); // Duration of ball1 animation
    };

    const startBall2 = () => {
      resetAnimations();
      ball2.classList.add('animate');
      setTimeout(startBall3, 2000); // Duration of ball2 animation
    };

    const startBall3 = () => {
      resetAnimations();
      ball3.classList.add('animate');
      setTimeout(startBall1, 2000); // Duration of ball3 animation
    };

    startBall1();
  };

  return (
    <section ref={sectionRef} className="slogan-section">
      <div className="slogan-content">
        <h1 style={{ fontWeight: 'bold' }}>{count}+ Projects Delivered</h1>
        <p>And Counting More...</p>
      </div>
      <div className="map-container">
        <img src={Sloganimg} alt="World Map with Circles and Lines" className="map-image" />
        <div ref={ball1Ref} className="animated-ball ball1"></div>
        <div ref={ball2Ref} className="animated-ball ball2"></div>
        <div ref={ball3Ref} className="animated-ball ball3"></div>
      </div>
    </section>
  );
};

export default Slogan;
