import React from "react";
import "./DigitalMarketingProcess.css";
import i1 from '../images/smart-portfolio-analysis-icon.png';
import i2 from '../images/services_creative-development (1).svg';
import i3 from '../images/dpm_ico-privacy_portal-pos.svg';
import i4 from '../images/launch.png';
import i5 from '../images/deliver.png';

const DigitalMarketingProcess = () => {
  const steps = [
    {
      number: 1,
      title: "Research",
      items: [
        "Needs Assessment",
        "Target Market",
        "Goals & Objectives",
        "Investment",
      ],
      icon: i1
    },
    {
      number: 2,
      title: "Digital Marketing Strategy",
      items: [
        "Needs Assessment",
        "Target Market",
        "Goals & Objectives",
        "Content Calendar",
        "Paid Media Schedule",
      ],
      icon: i2
    },
    {
      number: 3,
      title: "Design & Production",
      items: [
        "Needs Assessment",
        "Target Market",
        "Goals & Objectives",
        "Content Creation",
        "Content Approval",
      ],
      icon: i3
    },
    {
      number: 4,
      title: "Launch & Connect",
      items: [
        "Needs Assessment",
        "Target Market",
        "Goals & Objectives",
        "Lead Generation",
      ],
      icon: i4
    },
    {
      number: 5,
      title: "Deliver & Convert",
      items: [
        "Needs Assessment",
        "Target Market",
        "Goals & Objectives",
        "ROI Evaluation",
        "Measure & Improve",
      ],
      icon: i5
    },
  ];

  return (
    <div className="digital-marketing-process">
      <h1>Digital Marketing Process</h1>
      <div className="steps">
        {steps.map((step) => (
          <div key={step.number} className="process-step">
            <div className="process-step-icon">
              <img src={step.icon} alt={step.title} />
            </div>
            <div className="process-step-number">{step.number}</div>
            <h2>{step.title}</h2>
            <ul>
              {step.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalMarketingProcess;
