import React, { useEffect, useState } from "react";
import "./MarketingSection.css";
import m1 from "../images/m2.png";
import m2 from "../images/m1.png";
import m3 from "../images/m3.png";
import m4 from "../images/m4.png";
import m5 from "../images/m5.png";
import m6 from "../images/m6.png";
import Arrow4 from "./Arrow4";
import Arrow3 from "./Arrow3";
import logo86 from "../images/cir9.png";

const MarketingSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine image sizes based on screen width
  const getRotatingImageSize = () => {
    if (windowWidth <= 480) {
      return { width: '80px', height: '80px' };
    } else if (windowWidth <= 768) {
      return { width: '150px', height: '150px' };
    } else {
      return { width: '175px', height: '181px' };
    }
  };

  const rotatingImageSize = getRotatingImageSize();

  return (
    <div className="marketing-section">
      <div className="section-header">
        <h1>
          Marketing that builds brand and generates more revenue is the way of RUVA Digital Media. We leverage digital platforms and craft a compelling system that drives business.
        </h1>
      </div>
      <div className="section-content">
        <div className="content-item">
          <div className="image-wrapper">
            <img src={m2} alt="Description 1" />
            <img
              className="rotating-image"
              style={{
                top: '2px',
                left: '-1px',
                width: rotatingImageSize.width,
                height: rotatingImageSize.height
              }}
              src={m4}
              alt="Decorative"
            />
            <img
              className="rotating-image1"
              style={{
                top: '95px',
                right: '-6px',
                width: rotatingImageSize.width,
                height: rotatingImageSize.height
              }}
              src={m5}
              alt="Decorative"
            />
            <img
              className="rotating-image"
              style={{
                bottom: '0px',
                left: '20%',
                width: rotatingImageSize.width,
                height: rotatingImageSize.height
              }}
              src={m6}
              alt="Decorative"
            />
          </div>
          <br></br>
          <div className="text-wrapper">
            <h2>
              We Craft Experience For Business To Help Reach Our Customers
            </h2>
            <p style={{ fontSize: '1.5em', fontFamily: 'Kumbh Sans' }}>
              Reach out to more customers with the help of our marketing campaigns and understand your customer persona. Improve reach, Increase Conversion and Satisfied Customer.
            </p>
            <button>Learn More</button>
          </div>
        </div>
    
      </div>

      <div className="content-item reverse">
        <div className="image-wrapper">
          <img src={m1} style={{marginTop:'90px'}} alt="Description 2" />
          <img
            className="rotating-image"
            style={{
              top: '369px',
              right: '205px',
              width: rotatingImageSize.width,
              height: rotatingImageSize.height
            }}
            src={m3}
            alt="Decorative"
          />
        </div>
        <div className="text-wrapper1" >
          <img className="small-logo" src={logo86} alt="Logo" />
          <h2 style={{marginTop:'-229px'}}>
            We Craft Compelling Content and Engaging Campaigns
          </h2>
          <p style={{ fontSize: '1.5rem', fontFamily: 'Kumbh Sans' }}>
            We are the leading Digital marketing agency in Pune. We have helped more than 40+ local business to rank online and grow organically.
          </p>
          <button>Learn More</button>
        </div>
      </div>
      
    </div>
  );
};

export default MarketingSection;