import React, { useState } from 'react';
import './Seo.css';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Star from '../Components/Star';
import ServAnimate from '../Components/ServAnimate';
import logo86 from "../images/se.png";
import ellipse from '../images/Ellipse 257.png';
import m16 from "../images/dgreen.png";
import m17 from "../images/pink.png";

function AI() {
  const [animate, setAnimate] = useState(false);
  const startAnimation = () => {
    setAnimate(true);
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="App">
      <Header />

      <section className="intro-section">
        <h1 className='text-start' style={{ color: 'blueviolet', textAlign: 'left', marginLeft: '-975px', marginTop: '-81px', fontWeight: "bold" }}>AI Marketing</h1>
        <div className='row'>
          <div className='col-md-6 col-sm-12 col-lg-6'>
            <img className='drop' src={m16} alt="m16" />
            <img style={{ marginTop: '-163px', marginLeft: '1038px', width: '28px', height: '28px' }} src={m17} alt="m17" />
            <p style={{ fontSize: '45px', fontFamily: 'Inter' }}>Add a pinch of creativity to your marketing campaign with AI—a new way to market your product and grab more attention. Using AI fast forward your marketing campaign & stand out from the crowd.</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 shape-container">
            <div className='cir1'></div>
            <div className='filled'>
              <div className='unfilled'></div>
            </div>
            <Star />
            <div className='diagonal'></div>
            <div className='polygon'></div>
            <div className='line'></div>
            <div className='square1'></div>
            <div className='triangle'></div>
            <div className='half-circle'></div>
            <div className='bigcir'>
              <img src={ellipse} alt="Description 2" style={{ animation: 'rotate 5s linear infinite', width: '100px', height: '100px' }} />
            </div>
            <div className='smallcir'></div>
          </div>
        </div>
      </section>

      <div className="p-5 my-5 section2 text-white" style={{ backgroundColor: "#EF259B", justifyContent: "left" }}>
        <h1>What is AI Marketing</h1>
        <p style={{ fontFamily: 'Inter', fontSize: '25px' }}>AI marketing is marketing that utilizes AI model tools to optimize the marketing campaign. It leverages data analysis and algorithms to automate tasks, gain customer insights, and personalize the customer journey.</p>
        <h3 style={{ color: 'purple' }}>What do we serve?</h3>
        <p style={{ fontWeight: '700', fontSize: '33px' }}>We use AI tools to derive data-driven strategies, hyper-personalization, and predictive analytics to craft catchy content, optimize campaigns, and elevate the customer experience.</p>
        <div className="section3 row">
          <div className="col-2">SEO</div>
          <div className="col-2">SMM</div>
          <div className="col-3">Content Marketing</div>
          <div className="col-3">Graphics Design</div>
          <div className="col-3">AI Marketing</div>
          <div className="col-3">AI Marketing</div>
          <div className="col-3">Google Ad's</div>
          <div className="col-4">Website Development</div>
        </div>
      </div>

      <section className="faq-section">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(0)}>
            How AI can help in marketing?
          </button>
          <div className={`faq-answer ${activeIndex === 0 ? 'show' : ''}`}>
            <p>AI can be a game-changer for marketers. It personalizes experiences for customers, predicts their needs for targeted campaigns, assists with content creation, and automates repetitive tasks. This frees up marketers to focus on strategy and creativity, while AI handles the heavy lifting and analysis.</p>
          </div>
        </div>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(1)}>
            How do I get started with AI marketing?
          </button>
          <div className={`faq-answer ${activeIndex === 1 ? 'show' : ''}`}>
            <p>SEO is important because it helps improve the visibility of your website in search engine results, leading to more traffic and potential customers.</p>
          </div>
        </div>
        <div className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(2)}>
            How can AI benefit my marketing efforts?
          </button>
          <div className={`faq-answer ${activeIndex === 2 ? 'show' : ''}`}>
            <p>To improve SEO ranking, focus on creating high-quality content, optimizing for relevant keywords, and building backlinks from reputable sources.</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default AI;
