import React, { useState } from 'react';
import './MultiStepForm.css';

const MultiStepForm = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [selectedService, setSelectedService] = useState('');
  const [workType, setWorkType] = useState('');
  const [formData, setFormData] = useState({
    project_description: '',
    name: '',
    email: '',
    country: '',
    mobile: ''
  });

  const nextStep = () => {
    if (step === 1 && !selectedService) return;
    if (step === 2 && !workType) return;
    setStep(step + 1);
  };

  const prevStep = () => setStep(step - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...formData, service: selectedService, work_type: workType };

    fetch('http://www.ruvadigitalmedia.com/Ruva Backend/submit_quote.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(data).toString()
    })
    .then(response => response.json())
    .then(data => {
      if (data.message) {
        alert(data.message);
        onClose();
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step">
            <h2>Design A Quote</h2>
            <p style={{color:'black'}}>What services would you like to opt?</p>
            <button className={`service-btn ${selectedService === 'UI / UX' ? 'selected' : ''}`} onClick={() => setSelectedService('UI / UX')}>UI / UX</button>
            <button className={`service-btn ${selectedService === 'Branding' ? 'selected' : ''}`} onClick={() => setSelectedService('Branding')}>Branding</button>
            <button className={`service-btn ${selectedService === 'Development' ? 'selected' : ''}`} onClick={() => setSelectedService('Development')}>Development</button>
            <button className={`service-btn ${selectedService === 'Marketing' ? 'selected' : ''}`} onClick={() => setSelectedService('Marketing')}>Marketing</button>
            <button className={`service-btn ${selectedService === 'All Services' ? 'selected' : ''}`} onClick={() => setSelectedService('All Services')}>All Services</button>
            <button className="continue-btn" onClick={nextStep} disabled={!selectedService}>Continue →</button>
          </div>
        );
      case 2:
        return (
          <div className="step">
            <button className="back-btn" onClick={prevStep}>←</button>
            <h2>Design A Quote</h2>
            <p>How do you want to work?</p>
            <button className={`service-btn ${workType === 'Project' ? 'selected' : ''}`} onClick={() => setWorkType('Project')}>Project</button>
            <button className={`service-btn ${workType === 'Retainer' ? 'selected' : ''}`} onClick={() => setWorkType('Retainer')}>Retainer</button>
            <button className={`service-btn ${workType === 'Hours' ? 'selected' : ''}`} onClick={() => setWorkType('Hours')}>Hours</button>
            <button className="continue-btn" onClick={nextStep} disabled={!workType}>Continue →</button>
          </div>
        );
      case 3:
        return (
          <div className="step">
            <button className="back-btn" onClick={prevStep}>←</button>
            <h2>Design A Quote</h2>
            <p>Project Description</p>
            <textarea name="project_description" placeholder="Describe your project..." onChange={handleChange}></textarea>
            <button className="continue-btn" onClick={nextStep}>Continue →</button>
          </div>
        );
      case 4:
        return (
          <div className="step1" style={{width:'440px'}}>
            <button className="back-btn" onClick={prevStep}>←</button>
            <h2>Design A Quote</h2>
            <p>Hi! I am</p>
            <input type="text" name="name" placeholder="Your Name" onChange={handleChange} />
            <p>Reach me at</p>
            <input type="email" name="email" placeholder="Your Email" onChange={handleChange} />
            <p>Country Name</p>
            <input type="text" name="country" placeholder="Country Name" onChange={handleChange} />
            <p>Mobile No. +91</p>
            <input type="tel" name="mobile" placeholder="Mobile Number" onChange={handleChange} />
            <button className="send-btn" onClick={handleSubmit}>SEND</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="multi-step-form">
      {renderStep()}
    </div>
  );
};

export default MultiStepForm;
