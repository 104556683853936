import React from "react";
import "./Services.css";
import Arrow2 from "./Arrow2";
import m10 from "../images/green.png";

const Services = () => {
  return (
    <section className="services ">
      <h2 style={{padding:'80px',marginTop:'-67px'}}>Our Services</h2>

      
      <img className="colorball-green" src={m10}/>

      <div className="services-container">
        <div className="service">
          <h3>Content Marketing</h3>
          <p>
          Content is the king. And 
            we work on it. Elevate your
          brand with a content 
         marketing that drives
         traffic and leads for your
         business. 
          </p>
        </div>
        <div className="service">
          <h3>Social Media Marketing</h3>
          <p>
          Engage with your 
customers on Social
Media platforms with our 
dedicated SMM 
campaigns.
Get more eyeballs and
become the talk of the 
town.                                                                                    






 {" "}
          </p>
        </div>
        <div className="service">
          <h3>Search Engine Marketing (SEM)</h3>
          <p>
          Dominate search results
with our PPC campaign. 
Catchy ads, proper 
messaging and relevant
traffic all in one campaign.
Get more conversion with 
our help.{" "}
          </p>
        </div>
      
        <div className="service branding">
          <h3>Branding</h3>
          <p>
          Rank and drive traffic to 
your product and services
with our in-depth SEO 
strategy. SEO is a test 
match, longer the time 
you spend on the crease, 
more traffic you generate 
with one strategy. {" "}
          </p>
        </div>
      </div>
   
    </section>
  );
};

export default Services;
