import React,{useState} from 'react';
import './Seo.css';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Star from '../Components/Star';
import ServAnimate from '../Components/ServAnimate';
import logo86 from "../images/se.png";
import ellipse from '../images/Ellipse 257.png';
import m16 from "../images/dgreen.png";
import m17 from "../images/pink.png";




function ContentMarketing() {
  const [animate, setAnimate] = useState(false);

  const startAnimation = () => {
    setAnimate(true);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="App">
     <Header/>
    
                <section className="intro-section">
                <h1 className='text-start' style={{color:'blueviolet',textAlign:'left',marginLeft:'-970px',marginTop:'-81px',fontWeight:"bold"}}>Content Marketing</h1>
       <div className='row'>
        <div className='col-md-6 col-sm-12 col-lg-6'>

        <img style={{marginTop:'-80px',marginLeft:'700px' , width:'50px',height:'50px'}} src={m16}></img>

        <img style={{marginTop:'-227px',marginLeft:'1038px' , width:'28px',height:'28px'}} src={m17}></img>



          <p>Everything revolves 
around content. We help 
companies by crafting 
engaging content that 
builds awareness and 
drives traffic. Our 
production team is 
brimming with creative 
ideas to help businesses
 grow.</p>
        </div>
       <div className="col-sm-12 col-md-6 col-lg-6 shape-container mt-5">
           
            <div className='cir1'></div>
            <div className='filled'>
              <div className='unfilled'></div>
            </div>
            <Star></Star>
            <div className='diagonal'></div>
            <div className='polygon'></div>
            <div className='line'></div>
            <div className='square1'></div>
            <div className='triangle'></div>
            <div className='half-circle'></div>
            <div className='bigcir'> <img src={ellipse} alt="Description 2" style={{animation:'rotate 5s linear infinite',width:'100px',height:'100px'}} /></div>
            <div className='smallcir'></div>
    </div> 
       </div>
          
      </section>
    
    

      
      
      <div className="p-5 my-5 section2 text-white" style={{backgroundColor:"#EF259B",justifyContent:"left"}}>

                    <h1>What is Content Marketing?</h1>
                    <p style={{fontSize:'25px',fontFamily:'inter'}}>Content marketing is a marketing strategy used to attract consumers, 
increase awareness, and engagement, and retain an audience with the help
of content. The content can be in written format, videos, podcasts, or in
any other consumable format.</p>
                         <h3 style={{color:'#4F21A7'}}>what do we serve?</h3>
                         <p style={{fontWeight: '700', fontSize: '33px'}}>We serve a dedicated strategy with a 
balance of message and CTA
(click to action). The main reason behind 
content marketing is to gain more leads
and increase engagement.</p>
                         <div className="section3 row">
                         <div className="col-2">SEO</div>
                         <div className="col-2">SMM</div>
                         <div className="col-3">Content Marketing</div>
                         <div className="col-3">Graphics Design</div>
                         <div className="col-3">AI Marketing</div>
                         <div className="col-3">AI Marketing</div>
                         <div className="col-3">Google Ad's</div>
                         <div className="col-4">Website Developement</div>
                         </div>
                </div>
      
                <section className="faq-section">
      <h2>Frequently Asked Questions (FAQ)</h2>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(0)}>
        What type of content is best?
        </button>
        <div className={`faq-answer ${activeIndex === 0 ? 'show' : ''}`}>
          <p>The best type of content is user-centric content. </p>
          <p>The content that is catered to the audience is
 considered the best content. However, many types of content can be used to reach out
 to users. </p>
          
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(1)}>
        What type of content is trending?

        </button>
        <div className={`faq-answer ${activeIndex === 1 ? 'show' : ''}`}>
          <p>The short types of content such as reels, and shorts are trending. Basically, short-form videos are the trend of the time.</p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(2)}>
        Which platform is best for content marketing?

        </button>
        <div className={`faq-answer ${activeIndex === 2 ? 'show' : ''}`}>
          <p>The platforms that your audience are using is the best platforms for content marketing. For many, it can be Instagram, Facebook, & YouTube. But for some, it can be X(Twitter) or LinkedIn. It varied as per your business audience profile.  
</p>
        </div>
      </div>
    </section>
 
    <Footer/>
    </div>
  );
}

export default ContentMarketing;