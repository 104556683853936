import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Importing WhatsApp icon from react-icons

const WhatsAppButton = () => {
  // Hardcoded WhatsApp URL for testing
  const whatsappUrl = `https://wa.me/918275440068?text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20your%20services.`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        backgroundColor: '#25D366',
        color: 'white',
        borderRadius: '50%',
        textDecoration: 'none',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '1000'
      }}
    >
      <FaWhatsapp size={24} /> {/* WhatsApp icon */}
    </a>
  );
};

export default WhatsAppButton;
